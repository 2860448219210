import React, { useEffect, useState } from 'react';
import baseURL from '../url';
import './NumerosRifas.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons'; // Importa el ícono de búsqueda
import Modal from 'react-modal';
import whatsappIcon from '../../images/wpp.png';
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2';
Modal.setAppElement('#root'); // Especificar el elemento raíz para la accesibilidad

export default function NumerosRifas() {
    const [numeros, setNumeros] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contactos, setContactos] = useState([]);
    const [name, setName] = useState('');
    const [telefono, setTelefono] = useState('');
    const [identificacion, setIdentificacion] = useState('');
    const [direccion, setDireccion] = useState('');
    const { idRifa } = useParams();
    const [rifa, setRifa] = useState(null);
    const [rifas, setRifas] = useState([]);
    const [numerosSeleccionados, setNumerosSeleccionados] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    useEffect(() => {
        cargarNumeros();
        cargarContacto();
        cargarRifas();
    }, [idRifa]);

    const cargarNumeros = () => {
        setLoading(true);
        fetch(`${baseURL}/numeroGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const numerosFiltrados = data.numeros.filter(e => e.idRifa === parseInt(idRifa));
                setNumeros(numerosFiltrados);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar numeros:', error);
                setLoading(false);
            });
    };

    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto.reverse()[0] || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas || []);
                // Busca la rifa actual basada en el idRifa de los parámetros
                const rifaActual = data.rifas.find(rifa => rifa.idRifa === parseInt(idRifa));
                setRifa(rifaActual); // Guarda la rifa actual en el estado
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar rifas:', error);
                setLoading(true);
            });
    };
    const generarNumero = () => {
        if (rifa?.estado === 'finalizada') {
            Swal.fire(
                'Rifa Finalizada',
                'No se pueden generar números porque la rifa ha finalizado.',
                'info'
            );
            return;
        }

        const numerosDisponibles = numeros.filter(numero => numero.estado === 'disponible');
        if (numerosDisponibles.length > 0) {
            const numeroAleatorio = numerosDisponibles[Math.floor(Math.random() * numerosDisponibles.length)];
            if (!numerosSeleccionados.some(item => item.idNumero === numeroAleatorio.idNumero)) {
                setNumerosSeleccionados([...numerosSeleccionados, numeroAleatorio]);
            }
        } else {
            Swal.fire(
                'Error',
                'No hay números disponibles para seleccionar.',
                'error'
            );
        }
    };

    const handleNumeroClick = (numero) => {
        if (numerosSeleccionados.some(item => item.idNumero === numero.idNumero)) {
            setNumerosSeleccionados(numerosSeleccionados.filter(item => item.idNumero !== numero.idNumero));
        } else {
            setNumerosSeleccionados([...numerosSeleccionados, numero]);
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleOpenModal2 = () => {
        setShowModal2(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleCloseModal2 = () => {
        setShowModal2(false);
    };

    const actualizarNumerosSeleccionados = async () => {
        const idNumeros = numerosSeleccionados.map(numero => numero.idNumero);

        // Datos para actualizar los números
        const data = {
            idNumeros: idNumeros,
            nombre: name,
            telefono: telefono,
            estado: 'pendiente',
            identificacion: identificacion,
            direccion: direccion
        };

        try {
            const response = await fetch(`${baseURL}/numeroPutClient.php`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();
            if (result.mensaje) {
                console.log(result.mensaje);
            } else if (result.error) {
                console.error(result.error);
            }
        } catch (error) {
            console.error('Error al actualizar los números:', error);
        }
    };

    const handleWhatsappMessage = async () => {
        const phoneNumber = contactos?.telefono;

        if (!name || !telefono || !identificacion || !direccion) {
            Swal.fire(
                'Error!',
                'Por favor, complete los campos.',
                'error'
            );
            return;
        }

        // Actualizar los números antes de enviar el mensaje de WhatsApp
        await actualizarNumerosSeleccionados();

        // Extraemos solo los números seleccionados
        const numeros = numerosSeleccionados.map(numero => numero.numero).join(', ');

        const title = rifa?.nombre?.replace(/\s+/g, ' ')

        // Creamos el mensaje de WhatsApp
        const message = `Hola 🌟 quiero participar en el sorteo de ${title}\n\n✅Nombre: *${name}* \n\n✅Teléfono: *${telefono}*\n\n✅Identificacion: *${identificacion}*\n\n✅Direccion: *${direccion}*.\n\n✅Quisiera reservar los siguientes números:\n\n✅ *${numeros}*\n   `;

        // Generamos la URL para WhatsApp
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

        // Abrimos WhatsApp en una nueva pestaña
        window.open(whatsappUrl, '_blank');
        setTimeout(() => {
            window.location.reload();

        }, 1000);

    };
    const borrarSeleccion = () => {
        setNumerosSeleccionados([])
        handleCloseModal2()
        handleCloseModal()
    }

    return (
        <div className='ContentNumeros'>
            {numeros?.length > 0 && (
                <div className='titleSection'>
                    <h2>Selecciona tu número</h2>
                    <FontAwesomeIcon icon={faAngleDoubleRight} className='iconTitle' />
                </div>
            )}
            {numeros?.length > 0 && (
                <button
                    className="generar"
                    onClick={generarNumero}
                    disabled={rifa?.estado === 'finalizada'}
                >
                    <FontAwesomeIcon icon={faSearch} /> Maquinita de la suerte
                </button>
            )}
            {loading ? (
                <>Cargando...</>
            ) : (
                <div className='DataGrapNumber'>
                    {numeros?.map(item => (
                        <button
                            key={item.idNumero}
                            className={
                                numerosSeleccionados.some(n => n.idNumero === item.idNumero)
                                    ? 'selectedBtn'
                                    : (item.estado === 'disponible' ? 'activeBtn' : 'descativeBtn')
                            }
                            disabled={rifa?.estado === 'finalizada' || item.estado !== 'disponible'} // Bloquea si la rifa está finalizada o el número no está disponible
                            onClick={() => handleNumeroClick(item)}
                        >
                            <strong>{item.numero}</strong>
                        </button>
                    ))}
                </div>

            )}

            {numerosSeleccionados?.length > 0 && (
                <button className="openModalBtn" onClick={handleOpenModal}>
                    Ver mis numeros  ( {numerosSeleccionados?.length} )
                </button>
            )}

            <Modal
                isOpen={showModal}
                onRequestClose={handleCloseModal}
                contentLabel="Números Seleccionados"
                className="modal-cart"
                overlayClassName="overlay-cart"
            >
                <div className='deFLex'>
                    <button onClick={handleCloseModal}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    <button onClick={borrarSeleccion}>Limpiar numeros seleccionados</button>
                </div>
                <div className="modal-send-form">
                    <div className='contenModal'>
                        {numerosSeleccionados?.map(numero => (
                            <button className='selectedBtn' key={numero.idNumero}><strong>{numero.numero}</strong></button>
                        ))}
                    </div>
                    {numerosSeleccionados?.length > 0 && (
                        <button className="wpp" onClick={handleOpenModal2}>
                            Reservar
                            <img src={whatsappIcon} alt="whatsappIcon" />
                        </button>
                    )}

                </div>

                <Modal
                    isOpen={showModal2}
                    onRequestClose={handleCloseModal2}
                    contentLabel="Números Seleccionados"
                    className="modal-cart"
                    overlayClassName="overlay-cart"
                >
                    <div className='deFLex'>
                        <button onClick={handleCloseModal2}><FontAwesomeIcon icon={faArrowLeft} /></button>
                        <h4>Reserva tus números</h4>

                    </div>
                    <div className="modal-send-form">
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Nombre (obligatorio)'
                        />

                        <input
                            type="number"
                            id="telefono"
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                            placeholder='Telefono (obligatorio)'
                        />
                        <input
                            type="number"
                            id="identificacion"
                            value={identificacion}
                            onChange={(e) => setIdentificacion(e.target.value)}
                            placeholder='Identificacion (obligatorio)'
                        />
                        <input
                            type="text"
                            id="direccion"
                            value={direccion}
                            onChange={(e) => setDireccion(e.target.value)}
                            placeholder='Direccion (obligatorio)'
                        />
                        <button className="wpp" onClick={handleWhatsappMessage}>
                            Reservar
                            <img src={whatsappIcon} alt="whatsappIcon" />
                        </button>
                    </div>
                </Modal>
            </Modal>
        </div>
    );
}
